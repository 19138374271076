import {Component, OnInit, Input, EventEmitter, ViewChild, Output, OnChanges} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder , Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileStatus } from '@app/core/classes/status';
import { SettingsService } from '@app/core/settings/settings.service';
import {IS1InputSelectItem, S1ButtonType, S1Table, S1UIService} from '@app/s1';
import {IProfile, IProfileAsset, IProfileSetting} from '@app/shared/models/profile';
import { ProfilesService } from '../../../shared/services/profiles.service';

@Component({
  selector: 'profile-settings-form',
  templateUrl: './profile-settings-form.component.html',
  styleUrls: ['./profile-settings-form.component.scss']
})
export class ProfileSettingsFormComponent implements OnInit, OnChanges {
  profileSettingsForm: UntypedFormGroup;
  idCompany: number;
  profile: any;
  insertMode: boolean;
  readOnlyMode: boolean;
  s1ButtonType = S1ButtonType;
  @Input() profileId: number;

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private ui: S1UIService, private profilesService: ProfilesService, private settings: SettingsService) {
    this.idCompany = settings.getCompany().code;
    this.insertMode = true;
    this.readOnlyMode = false;

    this.profileSettingsForm = this.formBuilder.group({
      description: null,
      region: this.isCompanyUser() ? null : [null, Validators.required],
      site: this.isRegionUser() ? null : [null, Validators.required],
      group: this.isGroupUser() ? null : [null, Validators.required],
      brightnessRadio: true,
      brightness: 50,
      barsVisibility: false,
      barVisibilityTop: false,
      barVisibilityBottom: false,
      screentimeoutRadio: true,
      screentimeout: 30,
      dailyReboot: false,
      dailyRebootTime: null,
      dailyRebootWhenCharging: false,
      locale: null,
      timezone: null,
    });

    this.profileSettingsForm.controls.dailyReboot.valueChanges.subscribe(val => {
      if(!val) {
        this.profileSettingsForm.controls.dailyRebootTime.patchValue(null);
        this.profileSettingsForm.controls.dailyRebootWhenCharging.patchValue(null);
      }
    });

    this.profileSettingsForm.controls.barsVisibility.valueChanges.subscribe(val => {
      this.profileSettingsForm.controls.barVisibilityTop.patchValue(val);
      this.profileSettingsForm.controls.barVisibilityBottom.patchValue(val);
    });

    this.profileSettingsForm.controls.barVisibilityTop.valueChanges.subscribe(val => {
      if(this.profileSettingsForm.controls.barsVisibility.value && !val && !this.profileSettingsForm.controls.barVisibilityBottom.value) {
        this.profileSettingsForm.controls.barsVisibility.patchValue(false);
      }
    });

    this.profileSettingsForm.controls.barVisibilityBottom.valueChanges.subscribe(val => {
      if(this.profileSettingsForm.controls.barsVisibility.value && !val && !this.profileSettingsForm.controls.barVisibilityTop.value) {
        this.profileSettingsForm.controls.barsVisibility.patchValue(false);
      }
    });

  }

  ngOnInit(): void {

    if(this.profileId){
      this.profilesService.getProfileSetting(this.profileId).subscribe(settingProfile => {
        this.profile = settingProfile;
        this.insertMode = false;
        this.readOnlyMode = !(!this.profile.hasChildren && this.isAllowedRegion());

        let datetime: any;
        if(!this.insertMode && this.profile?.dailyRebootTime) { //editMode get date type, readMode get string type
          datetime = new Date();
          let parts = this.profile?.dailyRebootTime.split(':');
          datetime.setHours(parseInt(parts[0]), parseInt(parts[1]), parseInt(parts[2]));
        } else {
          datetime = this.profile?.dailyRebootTime;
        }

        this.profileSettingsForm.patchValue({
          description: this.profile.description,
          region: this.profile.idRegion,
          site: this.profile.idSite,
          group: this.profile.idGroup,
          brightnessRadio: this.profile?.brightness!=null ? true: false,
          brightness: this.profile?.brightness || 0,
          barsVisibility: this.profile?.barsVisibility,
          barVisibilityTop: this.profile?.barsVisibility && this.profile?.barVisibilityTop,
          barVisibilityBottom: this.profile?.barsVisibility && this.profile?.barVisibilityBottom,
          screentimeoutRadio: (this.profile?.screentimeout && this.profile?.screentimeout > 0) ? true: false,
          screentimeout: this.profile?.screentimeout,
          dailyReboot: this.profile?.dailyReboot,
          dailyRebootTime: datetime,
          dailyRebootWhenCharging: this.profile?.whenCharging,
          locale: this.profile?.locale,
          timezone: this.profile?.timezone
        });
        //seleziona site con id dell'oggetto parto
        //SOLUZIONE_1
        /*
        setTimeout(() => {
          this.profileSettingsForm.patchValue({
            site: this.profileNetwork.idSite
          });
        });*/
        //SOLUZIONE_1
        this.profileSettingsForm.get('region').valueChanges.subscribe(() => {
          this.profileSettingsForm.patchValue({
            site: this.profile.idSite
          });
        });
      });
    }

  }

  ngOnChanges() {}

  isCompanyUser() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0;
  }

  isRegionUser() {
    return !this.settings.isOperatore() || this.settings.getSites()?.length==0;
  }

  isGroupUser() {
    return !this.settings.isOperatore() || this.settings.getGroups()?.length==0;
  }

  isAllowedRegion() {
    return !this.settings.isOperatore() || this.settings.getRegions()?.length==0 || this.settings.getRegions()?.filter(region => region.code == this.profile?.idRegion).length > 0;
  }

  timezoneChange(type) {
    this.profileSettingsForm.patchValue({
      timezone: type ? type.code: null
    });
  }

  localeChange(type) {
    this.profileSettingsForm.patchValue({
      locale: type ? type.code: null
    });
  }

  save() {

    let datetime: string;
    let dailyReboot = this.profileSettingsForm.controls.dailyRebootTime.value;
    if(dailyReboot) {
      if(typeof dailyReboot !== 'string') {
        let hours = dailyReboot.getHours();
        let minutes = dailyReboot.getMinutes();
        let seconds = dailyReboot.getSeconds();
        datetime = hours + ':' + minutes + ':' + seconds;
      } else {
        datetime = dailyReboot;
      }
    }

    if(this.profileSettingsForm.controls.brightnessRadio.value) {

      if(this.profileSettingsForm.controls.brightness.value < 0) {
        this.profileSettingsForm.patchValue({
          brightnessRadio: false,
          brightness: 0
        });
      } else {
        this.profileSettingsForm.patchValue({
          brightnessRadio: true,
          brightness: parseInt(this.profileSettingsForm.controls.brightness.value)
        });
      }
    } else {
      this.profileSettingsForm.patchValue({
        brightnesstRadio: false,
        brightness: 0
      });
    }

    if(this.profileSettingsForm.controls.screentimeoutRadio.value) {

      if(!parseInt(this.profileSettingsForm.controls.screentimeout.value) || this.profileSettingsForm.controls.screentimeout.value <= 0) {
        this.profileSettingsForm.patchValue({
          screentimeoutRadio: false,
          screentimeout: 0
        });
      } else {
        this.profileSettingsForm.patchValue({
          screentimeoutRadio: true,
          screentimeout: parseInt(this.profileSettingsForm.controls.screentimeout.value)
        });
      }
    } else {
      this.profileSettingsForm.patchValue({
        screentimeoutRadio: false,
        screentimeout: 0
      });
    }

    if (this.profile) {
      this.profile.description = this.profileSettingsForm?.controls.description.value;
      this.profile.brightness = this.profileSettingsForm.controls.brightnessRadio.value ? this.profileSettingsForm.controls.brightness.value : null;
      this.profile.barsVisibility = this.profileSettingsForm.controls.barsVisibility.value;
      this.profile.barVisibilityTop = this.profileSettingsForm.controls.barVisibilityTop.value;
      this.profile.barVisibilityBottom = this.profileSettingsForm.controls.barVisibilityBottom.value;
      this.profile.screentimeout = this.profileSettingsForm.controls.screentimeout.value;
      this.profile.dailyReboot = this.profileSettingsForm.controls.dailyReboot.value;
      this.profile.dailyRebootTime = datetime;
      this.profile.whenCharging = this.profileSettingsForm.controls.dailyRebootWhenCharging.value;
      this.profile.locale = this.profileSettingsForm.controls.locale.value;
      this.profile.timezone = this.profileSettingsForm.controls.timezone.value;
      this.profile.idRegion = this.profileSettingsForm?.controls.region.value;
      this.profile.idSite = this.profileSettingsForm?.controls.site.value;
      this.profile.idGroup = this.profileSettingsForm?.controls.group.value;
      this.updateSettingProfile(this.profile);
    } else {
      const newSettingsProfile: IProfileSetting = {
        idCompany: this.idCompany,
        idRegion: this.profileSettingsForm?.controls.region.value,
        idSite: this.profileSettingsForm?.controls.site.value,
        idGroup: this.profileSettingsForm?.controls.group.value,
        description: this.profileSettingsForm?.controls.description.value,
        brightness : this.profileSettingsForm.controls.brightnessRadio.value ? this.profileSettingsForm.controls.brightness.value : null,
        barsVisibility : this.profileSettingsForm.controls.barsVisibility.value,
        barVisibilityTop : this.profileSettingsForm.controls.barVisibilityTop.value,
        barVisibilityBottom : this.profileSettingsForm.controls.barVisibilityBottom.value,
        screentimeout : this.profileSettingsForm.controls.screentimeout.value,
        dailyReboot : this.profileSettingsForm.controls.dailyReboot.value,
        whenCharging : this.profileSettingsForm.controls.dailyRebootWhenCharging.value,
        dailyRebootTime : datetime,
        locale : this.profileSettingsForm.controls.locale.value,
        timezone : this.profileSettingsForm.controls.timezone.value
      }
      this.createSettingProfile(newSettingsProfile);
    }

  }

  private createSettingProfile(profile: IProfileSetting) {
    this.profilesService.createProfileSetting(profile).subscribe(newProfile => {
      if (newProfile) {
        this.ui.showSuccessToast('msg.operation_ok');
        this.router.navigate(['/profiles', newProfile.idProfile, newProfile.nameType]);
      }
    });
  }

  private updateSettingProfile(profile: IProfileSetting) {
    this.profilesService.updateProfileSetting(profile).subscribe(profileUpdated => {
      if (profileUpdated) {
        this.ui.showSuccessToast('msg.operation_ok');
      }
    });
  }


}
