import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { BaseChartDirective } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { TreeModule } from '@bugsplat/angular-tree-component';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';

import { S1Module } from '@app/s1/s1.module';
import { InputCompanySelectComponent } from './components/input-company-select/input-company-select.component';
import { InputRegionSelectComponent } from './components/input-region-select/input-region-select.component';
import { InputRegionMultiSelectComponent } from './components/input-region-multiselect/input-region-multiselect.component';
import { InputBrandSelectComponent } from './components/input-brand-select/input-brand-select.component';
import { InputSiteSelectComponent } from './components/input-site-select/input-site-select.component';
import { InputSiteMultiSelectComponent } from './components/input-site-multiselect/input-site-multiselect.component';
import { InputModelSelectComponent } from './components/input-model-select/input-model-select.component';
import { InputGroupSelectComponent } from './components/input-group-select/input-group-select.component';
import { UnitCellComponent } from './components/unit-cell/unit-cell.component';
import { UnitCommandsComponent } from './components/unit-commands/unit-commands.component';
import { TimestampToTimePipe } from './pipes/timestamp-to-time.pipe';
import { ProfileSettingsModalComponent } from './components/profile-settings-modal/profile-settings-modal.component';
import { ProfileNetworkModalComponent } from './components/profile-network-modal/profile-network-modal.component';
import { InputProfileTypeSelectComponent } from './components/input-profile-type-select/input-profile-type-select.component';
import { InputProfileListSelectComponent } from './components/input-profile-list-select/input-profile-list-select.component';
import { InputOsActionTypeSelectComponent } from './components/input-os-action-type-select/input-os-action-type-select.component';
import { InputOsListSelectComponent } from './components/input-os-list-select/input-os-list-select.component';
import { UnitFilesystemModalComponent } from './components/unit-filesystem-modal/unit-filesystem-modal.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { InputUserRoleSelectComponent } from './components/input-user-role-select/input-user-role-select.component';
import { ProfileFileManagerModalComponent } from './components/profile-file-manager-modal/profile-file-manager-modal.component';
import { AssetModalComponent } from './components/asset-modal/asset-modal.component';
import { ProfileAssignmentModalComponent } from '@app/routes/profiles/profile-assignment-modal/profile-assignment-modal.component';
import { ProfileAssignmentHierarchicalComponent } from './components/profile-assignment-hierarchical/profile-assignment-hierarchical.component';
import { ProfileAssignmentSingleComponent } from './components/profile-assignment-single/profile-assignment-single.component';
import { UnitSelectionModalComponent } from './components/unit-selection-modal/unit-selection-modal.component';
import { GroupSelectionModalComponent } from './components/group-selection-modal/group-selection-modal.component';
import { ResellerCompanySelectionComponent } from './components/reseller-company-selection/reseller-company-selection.component';
import { AssetSelectionModalComponent } from './components/asset-selection-modal/asset-selection-modal.component';
import { ProfileActivationStatisticCardComponent } from './components/profile-activation-statistic-card/profile-activation-statistic-card.component';
import { ProfileActivationHierarchicalComponent } from './components/profile-activation-hierarchical/profile-activation-hierarchical.component';
import { ProfileSettingsContentComponent } from './components/profile-settings-content/profile-settings-content.component';
import { ProfileNetworkContentComponent } from './components/profile-network-content/profile-network-content.component';
import { ProfileFileManagerContentComponent } from './components/profile-file-manager-content/profile-file-manager-content.component';
import { ProfileOsContentComponent } from './components/profile-os-content/profile-os-content.component';
import { ProfileActivationDateModalComponent } from './components/profile-activation-date-modal/profile-activation-date-modal.component';
import { ProfileActivationSingleComponent } from './components/profile-activation-single/profile-activation-single.component';
import { HierarchicalSelectionModalComponent } from './components/hierarchical-selection-modal/hierarchical-selection-modal.component';
import { ProfileOsModalComponent } from './components/profile-os-modal/profile-os-modal.component';
import { InputAssetTypeSelectComponent } from './components/input-asset-type-select/input-asset-type-select.component';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { AssetCardComponent } from './components/asset-card/asset-card.component';
import { ProfileDistributionCellComponent } from './components/profile-distribution-cell/profile-distribution-cell.component';
import { BatteryIconComponent } from './components/battery-icon/battery-icon.component';
import { UnitTimelineComponent } from './components/unit-timeline/unit-timeline.component';
import { FileStatusBadgeComponent } from './components/file-status-badge/file-status-badge.component';
import { DashboardSummaryCardComponent } from './components/dashboard-summary-card/dashboard-summary-card.component';
import { DashboardProfileCardComponent } from './components/dashboard-profile-card/dashboard-profile-card.component';
import { DashboardOsCardComponent } from './components/dashboard-os-card/dashboard-os-card.component';
import { InputExecuteWithTypeSelectComponent } from './components/input-executewith-type-select/input-executewith-type-select.component';
import { InputAgentListSelectComponent } from './components/input-agent-list-select/input-agent-list-select.component';
import { InputProfileStatusTypeSelectComponent } from './components/input-profile-status-type-select/input-profile-status-type-select.component';
import { InputTimezoneTypeSelectComponent } from './components/input-timezone-type-select/input-timezone-type-select.component';
import { InputSecurityNetworkTypeSelectComponent } from './components/input-security-network-type-select/input-security-network-type-select.component';
import { InputLocaleTypeSelectComponent } from './components/input-locale-type-select/input-locale-type-select.component';
import { InputOptionsSelectComponent } from './components/input-options-select/input-options-select.component';
import { InputIntervalRefreshSelectComponent } from './components/input-interval-refresh-select/input-interval-refresh-select.component';
import { InputDistributionStatusSelectComponent } from './components/input-distribution-status-select/input-distribution-status-select.component';
import { InputAePolicySelectComponent } from './components/input-ae-policy-select/input-ae-policy-select.component';
import { UnitAssetsModalComponent } from './components/unit-assets-modal/unit-assets-modal.component';
import { AssetUnitsModalComponent } from './components/asset-units-modal/asset-units-modal.component';
import { InputWallSelectComponent } from './components/input-wall-select/input-wall-select.component';
import { WallCardComponent } from './components/wall-card/wall-card.component';
import { WallsTabsComponent } from './components/walls-tabs/walls-tabs.component';
import { WallSummaryCardComponent } from './components/wall-summary-card/wall-summary-card.component';
import { NumericListPipe } from './pipes/numeric-list.pipe';
import { SummaryBadgeComponent } from './components/summary-badge/summary-badge.component';
import { StatisticCardComponent } from './components/statistic-card/statistic-card.component';
import { ChartGaugeComponent } from './components/chart-gauge/chart-gauge.component';
import { ChartDoughnutComponent } from './components/chart-doughnut/chart-doughnut.component';
import { ChartBarComponent } from './components/chart-bar/chart-bar.component';
import { ChartPieComponent } from './components/chart-pie/chart-pie.component';
import { TimeIconComponent } from './components/time-icon/time-icon.component';
import { UnitDetailModal } from './components/unit-detail-modal/unit-detail-modal.component';
import { UnitCardComponent } from './components/unit-card/unit-card.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { DateFromIntervalPipe } from './pipes/date-from-interval.pipe';
import { DispensingUnitCellComponent } from './components/dispensing-unit-cell/dispensing-unit-cell.component';
import { AePermissionPolicyModalComponent } from '@app/routes/ae/application-permission-policy-modal/application-permission-policy-modal.component';
import { AeManagedConfigurationModalComponent } from '@app/routes/ae/application-managed-configuration-modal/application-managed-configuration-modal.component';
import { AeApplicationConfigurationModalComponent } from '@app/routes/ae/application-configuration-modal/application-configuration-modal.component';
import { DashboardModalComponent } from './components/dashboard-modal/dashboard-modal.component';
import { InputGroupMultiSelectComponent } from './components/input-group-multiselect/input-group-multiselect.component';
import { InputBrandMultiselectComponent } from './components/input-brand-multiselect/input-brand-multiselect.component';
import {DashboardMdmComponent} from '@app/shared/components/dashboard-mdm/dashboard-mdm.component';
import {DashboardDispansingComponent} from '@app/shared/components/dashboard-dispansing/dashboard-dispansing.component';
import {ProfileAssetFormComponent} from '@app/shared/components/profile-asset-form/profile-asset-form.component';
import {ProfileNetworkFormComponent} from '@app/shared/components/profile-network-form/profile-network-form.component';
import {ProfileSettingsFormComponent} from '@app/shared/components/profile-settings-form/profile-settings-form.component';
import {ProfileAeFormConfigurationComponent} from '@app/shared/components/profile-ae-form-configuration/profile-ae-form-configuration.component';
import {UnitFailureCellComponent} from '@app/shared/components/unit-failure-cell/unit-failure-cell.component';
import {UnitFailureModalComponent} from '@app/shared/components/unit-failure-modal/unit-failure-modal.component';
import {UnitAskDisableModalComponent} from '@app/shared/components/unit-askDisable-modal/unit-askDisable-modal.component';
import { FileUploadButtonComponent } from './components/file-upload-button/file-upload-button.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputPlacheholderComponent } from './components/input-password-placheholder/input-placheholder.component';
import { UnitCellMinComponent } from './components/unit-cell-min/unit-cell-min.component';
import { CommandListComponent } from './components/command-list/command-list.component';
import {DashboardShopComponent} from "@app/shared/components/dashboard-shop/dashboard-shop.component";

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        BaseChartDirective,
        FileUploadModule,
        TreeModule,
        S1Module,
        RouterModule,
        TranslateModule
    ],
    providers: [
        ColorsService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        InputCompanySelectComponent,
        InputRegionSelectComponent,
        InputRegionMultiSelectComponent,
        InputBrandSelectComponent,
        InputSiteSelectComponent,
        InputSiteMultiSelectComponent,
        InputModelSelectComponent,
        InputGroupSelectComponent,
        InputGroupMultiSelectComponent,
        InputBrandMultiselectComponent,
        UnitCellComponent,
        UnitCommandsComponent,
        TimestampToTimePipe,
        InputAePolicySelectComponent,
        AePermissionPolicyModalComponent,
        AeManagedConfigurationModalComponent,
        AeApplicationConfigurationModalComponent,
        ProfileSettingsModalComponent,
        InputTimezoneTypeSelectComponent,
        InputSecurityNetworkTypeSelectComponent,
        InputOptionsSelectComponent,
        InputDistributionStatusSelectComponent,
        InputLocaleTypeSelectComponent,
        ProfileNetworkModalComponent,
        InputProfileTypeSelectComponent,
        InputProfileListSelectComponent,
        InputProfileStatusTypeSelectComponent,
        InputOsActionTypeSelectComponent,
        InputOsListSelectComponent,
        InputAgentListSelectComponent,
        UnitFilesystemModalComponent,
        UnitAssetsModalComponent,
        AssetUnitsModalComponent,
        FileSizePipe,
        InputUserRoleSelectComponent,
        ProfileFileManagerModalComponent,
        AssetModalComponent,
        ProfileAssignmentModalComponent,
        ProfileAssignmentHierarchicalComponent,
        ProfileAssignmentSingleComponent,
        UnitSelectionModalComponent,
        GroupSelectionModalComponent,
        ResellerCompanySelectionComponent,
        AssetSelectionModalComponent,
        ProfileActivationStatisticCardComponent,
        ProfileActivationHierarchicalComponent,
        ProfileSettingsContentComponent,
        ProfileNetworkContentComponent,
        ProfileFileManagerContentComponent,
        ProfileOsContentComponent,
        ProfileActivationDateModalComponent,
        ProfileActivationSingleComponent,
        HierarchicalSelectionModalComponent,
        ProfileOsModalComponent,
        InputAssetTypeSelectComponent,
        InputExecuteWithTypeSelectComponent,
        ProfileHeaderComponent,
        AssetCardComponent,
        ProfileDistributionCellComponent,
        BatteryIconComponent,
        TimeIconComponent,
        UnitTimelineComponent,
        FileStatusBadgeComponent,
        DashboardSummaryCardComponent,
        DashboardProfileCardComponent,
        DashboardOsCardComponent,
        DashboardModalComponent,
        InputIntervalRefreshSelectComponent,
        InputWallSelectComponent,
        WallsTabsComponent,
        WallSummaryCardComponent,
        WallCardComponent,
        DispensingUnitCellComponent,
        UnitCardComponent,
        UnitDetailModal,
        ChartPieComponent,
        ChartBarComponent,
        ChartDoughnutComponent,
        ChartGaugeComponent,
        SummaryBadgeComponent,
        StatisticCardComponent,
        NumericListPipe,
        SecondsToTimePipe,
        DateFromIntervalPipe,
        DashboardMdmComponent,
        DashboardDispansingComponent,
        ProfileAssetFormComponent,
        ProfileNetworkFormComponent,
        ProfileSettingsFormComponent,
        ProfileAeFormConfigurationComponent,
        UnitFailureCellComponent,
        UnitFailureModalComponent,
        UnitAskDisableModalComponent,
        FileUploadButtonComponent,
        InputPasswordComponent,
        InputPlacheholderComponent,
        UnitCellMinComponent,
        CommandListComponent,
        DashboardShopComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToastrModule,
        BaseChartDirective,
        FileUploadModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        S1Module,
        InputCompanySelectComponent,
        InputRegionSelectComponent,
        InputRegionMultiSelectComponent,
        InputBrandSelectComponent,
        InputSiteSelectComponent,
        InputSiteMultiSelectComponent,
        InputModelSelectComponent,
        InputGroupSelectComponent,
        InputGroupMultiSelectComponent,
        InputBrandMultiselectComponent,
        UnitCellComponent,
        UnitCommandsComponent,
        InputAePolicySelectComponent,
        AePermissionPolicyModalComponent,
        AeManagedConfigurationModalComponent,
        AeApplicationConfigurationModalComponent,
        ProfileSettingsModalComponent,
        InputTimezoneTypeSelectComponent,
        InputSecurityNetworkTypeSelectComponent,
        InputOptionsSelectComponent,
        InputDistributionStatusSelectComponent,
        InputLocaleTypeSelectComponent,
        ProfileNetworkModalComponent,
        InputProfileTypeSelectComponent,
        InputProfileListSelectComponent,
        InputProfileStatusTypeSelectComponent,
        InputOsActionTypeSelectComponent,
        InputOsListSelectComponent,
        InputAgentListSelectComponent,
        UnitFilesystemModalComponent,
        UnitAssetsModalComponent,
        AssetUnitsModalComponent,
        InputUserRoleSelectComponent,
        ProfileFileManagerModalComponent,
        AssetModalComponent,
        ProfileAssignmentModalComponent,
        ProfileAssignmentHierarchicalComponent,
        ProfileAssignmentSingleComponent,
        UnitSelectionModalComponent,
        GroupSelectionModalComponent,
        ResellerCompanySelectionComponent,
        AssetSelectionModalComponent,
        ProfileActivationStatisticCardComponent,
        ProfileActivationHierarchicalComponent,
        ProfileSettingsContentComponent,
        ProfileNetworkContentComponent,
        ProfileFileManagerContentComponent,
        ProfileOsContentComponent,
        ProfileActivationDateModalComponent,
        ProfileActivationSingleComponent,
        HierarchicalSelectionModalComponent,
        ProfileOsModalComponent,
        InputAssetTypeSelectComponent,
        InputExecuteWithTypeSelectComponent,
        ProfileHeaderComponent,
        AssetCardComponent,
        ProfileDistributionCellComponent,
        BatteryIconComponent,
        TimeIconComponent,
        UnitTimelineComponent,
        FileStatusBadgeComponent,
        DashboardSummaryCardComponent,
        DashboardProfileCardComponent,
        DashboardOsCardComponent,
        DashboardModalComponent,
        InputIntervalRefreshSelectComponent,
        InputWallSelectComponent,
        WallsTabsComponent,
        WallSummaryCardComponent,
        WallCardComponent,
        DispensingUnitCellComponent,
        UnitCardComponent,
        UnitDetailModal,
        ChartPieComponent,
        ChartBarComponent,
        ChartDoughnutComponent,
        ChartGaugeComponent,
        SummaryBadgeComponent,
        StatisticCardComponent,
        NumericListPipe,
        SecondsToTimePipe,
        DateFromIntervalPipe,
        DashboardMdmComponent,
        DashboardDispansingComponent,
        ProfileAssetFormComponent,
        ProfileNetworkFormComponent,
        ProfileSettingsFormComponent,
        ProfileAeFormConfigurationComponent,
        UnitFailureCellComponent,
        UnitAskDisableModalComponent,
        FileUploadButtonComponent,
        InputPasswordComponent,
        InputPlacheholderComponent,
        UnitCellMinComponent,
        CommandListComponent,
        DashboardShopComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
